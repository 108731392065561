import payload_plugin_TvGaB98K1anXfyEVgpKmeoQoKPZgfHBPx9K_w4zy8ko from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_n5vIFAvtHZA5gBXuPMVRvtBRU8UfAXJe8qfZOIpZxWQ from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.js";
import vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _00_load_app_config_7JOZYelINkr8KUxYDpohSUVam3n6lQZ4YCAQsQVRo7Q from "/Users/adria-fsbd/Documents/FSBD-Projects/app/plugins/00.load-app-config.ts";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_client_2D55BnWf_S9Sj7prrquAXDNgYnTUYwRMNIQ5C3mC9zs from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_SihZNInqpa1PiqGp921qiIhvG5nRKrw_I5xMtOqdnC4 from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/.cache/nuxt/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin__NsTyyXB_v600ojXacf8AhyIMLDwp2k62eFjLidDJdM from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/.cache/nuxt/.nuxt/vuefire-plugin.mjs";
import plugin_client_s_3oxQQJAK9fpEoIf1JTDFSiONROf26_bxsMP2ung0Y from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import charts_client_4d_mDqudWvN8WUwXwf3m9FaEq9KIE_oZut8Eud901Cs from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/widgets/plugins/charts.client.ts";
import directives_ZO_fa6I_nir41stt9EP7MjOjugaUdNg5lkxzes_1_uE from "/Users/adria-fsbd/Documents/FSBD-Projects/app/plugins/directives.ts";
import sentry_client__pPjSgmJ962Ia_DHaFD9WBCM_0yvVKg4utdt7amqGzk from "/Users/adria-fsbd/Documents/FSBD-Projects/app/plugins/sentry.client.ts";
import toastify_fnyQQNJwlCXoJP5AG_c_ntLFkcd_B7cxMvG4TBeIvOc from "/Users/adria-fsbd/Documents/FSBD-Projects/app/plugins/toastify.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
import vuetify_nuxt_plugin_client_vhPL_4E3qi2WkYEd3Y5XADFMLa2GBXOaOwhkLQcelWw from "/Users/adria-fsbd/Documents/FSBD-Projects/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_TvGaB98K1anXfyEVgpKmeoQoKPZgfHBPx9K_w4zy8ko,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  vuetify_i18n_n5vIFAvtHZA5gBXuPMVRvtBRU8UfAXJe8qfZOIpZxWQ,
  vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _00_load_app_config_7JOZYelINkr8KUxYDpohSUVam3n6lQZ4YCAQsQVRo7Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY,
  plugin_client_2D55BnWf_S9Sj7prrquAXDNgYnTUYwRMNIQ5C3mC9zs,
  vuefire_auth_plugin_client_SihZNInqpa1PiqGp921qiIhvG5nRKrw_I5xMtOqdnC4,
  vuefire_plugin__NsTyyXB_v600ojXacf8AhyIMLDwp2k62eFjLidDJdM,
  plugin_client_s_3oxQQJAK9fpEoIf1JTDFSiONROf26_bxsMP2ung0Y,
  charts_client_4d_mDqudWvN8WUwXwf3m9FaEq9KIE_oZut8Eud901Cs,
  directives_ZO_fa6I_nir41stt9EP7MjOjugaUdNg5lkxzes_1_uE,
  sentry_client__pPjSgmJ962Ia_DHaFD9WBCM_0yvVKg4utdt7amqGzk,
  toastify_fnyQQNJwlCXoJP5AG_c_ntLFkcd_B7cxMvG4TBeIvOc,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs,
  vuetify_nuxt_plugin_client_vhPL_4E3qi2WkYEd3Y5XADFMLa2GBXOaOwhkLQcelWw
]