
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _4049iX_PEWOGuKZGnVL_0MgkWHMhgre4GiAH5qtav5MjZYMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/404.vue?macro=true";
import { default as budgetcbHMvAwDR9vCbpQN695cJIp61lGzmzi0npG8BbGMfoEMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/budget.vue?macro=true";
import { default as expiredHsvNsOtAdmC0S4R5oxou86NmZV7tx3gn7oMYedthqmwMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/expired.vue?macro=true";
import { default as processedbtC_9FQr2lsF2A5j_3IlZhKF_KOZd3B_45Ol4vlLZ9uv8Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/processed.vue?macro=true";
import { default as index_45D2KBQ4UG9YYORnffUeWBNLiCUcZDyQYUmvVr7qwZhYMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/analytics/pages/analytics/index.vue?macro=true";
import { default as _91id_93xSIJjTdIuw_45axYdWKx4c1zU_45P0jMrIJZyu45fobofy8Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/[product]/[briefing]/[id].vue?macro=true";
import { default as createFCvhhFVCtb3N_Z4eqqIoFmeaHrj5jme1oCDmK4ZOWooMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/create.vue?macro=true";
import { default as index02yCZl9bjWwbF5K88FpXDRtGFmdlFBbmEJLa_45ExKcX0Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/index.vue?macro=true";
import { default as _91_46_46_46slug_93oWUrBBv8K5oPbvHkTOtjJx1oBOmT_eSBx7CifkLvfHAMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/[...slug].vue?macro=true";
import { default as createISWC9posls2ssc4rxwyt_45qcZSbUoK3pV7ctB_45c0xKKsMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/create.vue?macro=true";
import { default as index0ngG15PTqhnXs_e1JLLm_45bffg8ufsOAftkmf54JFBWkMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/index.vue?macro=true";
import { default as _91id_932v28E11pEPvYPLWxsIwByolz8_45coWhDNXdw2d3KN0DgMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/[customer]/[id].vue?macro=true";
import { default as create0dRizeukMlGhhmDg1sOWjpE96groHV_450ykb5vHjuvkgMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/create.vue?macro=true";
import { default as indexTylpoOeypLlx4VMg7NWwW_45KbxF9xt9w5cm5SuXm0aysMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/index.vue?macro=true";
import { default as creategASV_eaNAU_45J82xtbLoLw4JCvX4uBsERgD7qJFQwq9UMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/create.vue?macro=true";
import { default as indexdGqaYA7d8U4j3Ehlv6HSbsQHOgYQPyXg0TLkE7mFBn4Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/index.vue?macro=true";
import { default as createhZ8nq0Wz4I0pjW3kZPyEstbJBdtc873yAa3dzQHCO8MMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/create.vue?macro=true";
import { default as indexrJ2QbS9hJyMVSRWFQLDYS5fLIOR2EowPYyd9pcoXpwQMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/index.vue?macro=true";
import { default as _91_46_46_46slug_93stiMQhnAXsZasTADK8ujp_Scu_EQKcW_jwXYc6JnQcIMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/[...slug].vue?macro=true";
import { default as createVRE0pI4eI4UNXmsGGNnUDDgH7htsaUUA545ZyEfOwTsMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/create.vue?macro=true";
import { default as indexoJosAXO11pmQwy5pG5X3DCMUypHFTHIrdQquwWXXf70Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/index.vue?macro=true";
import { default as _91_46_46_46slug_93SleujWW1cD6_457Jw7RuQaf_45W8ATC_45UwvLm5PZpGUeVvkMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/[...slug].vue?macro=true";
import { default as createe0jrkR96Usjj85czclyPxjUvTmvkQPwaCdOt7eEVscEMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/create.vue?macro=true";
import { default as indexAIFwMMKokl0iinlcTptf05vxXf4BNNFFxNul3ABABEwMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/index.vue?macro=true";
import { default as _91_46_46_46slug_93GjAHANvKK0jNu2wpUxPdsQc2P__459Sc_45n_hPhn_45tFWXQMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/[...slug].vue?macro=true";
import { default as createhbPKYU1ThoIugM4JrefQ5wAUaF5MvIathKJP_454QeKQoMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/create.vue?macro=true";
import { default as indexS0NA0L0DHYtedI_2vYyFfFDRooSzD16YElG0IsijKPEMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/index.vue?macro=true";
import { default as _91_46_46_46slug_93r3c3Pe5ofSD9vcsSUBx0CosMc031FkAR3GBAW35ksHUMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/[...slug].vue?macro=true";
import { default as createpzVy_45QN2Im_458O0nRfqi18LMy8D_45pX2wVwk0F2VqO0XoMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/create.vue?macro=true";
import { default as index3_45aLA8VLgYTXqdyobumwosdJEH6sJtx2U3865qKtc7oMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/index.vue?macro=true";
import { default as indextIdr_457B5c9H_TN9FEwXy_LOQkX6tvl_45OZX70kyXy_z8Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/widgets/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93mO__45zREzthK_45Xp1qLJQrzSMMNnNNk2PFXkpq_45ar11gsMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/[...slug].vue?macro=true";
import { default as indexWZHbd9CtKMIuPuTM8kXVwaYxd7pYgQs3rFFqymsjQ70Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/activate/index.vue?macro=true";
import { default as createjNzs3GcSdWd4vY5bQx_45y90e3MjliWlrjUYadKTH9_ZsMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/create.vue?macro=true";
import { default as indexCvt7WOpoBquyuttotODD4CYe7wg_DnO_45HSqknCPRu7UMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/index.vue?macro=true";
import { default as loginJnXkW7XIZYBTy7N_BRVCuULOU3jGKBpK_45bv7UhPj9NsMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/login/pages/login.vue?macro=true";
import { default as _91_46_46_46slug_930UUz0kL2Qfiwz_F5hxGNslqxABkw8pV9QfVj_45CKm16YMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/[...slug].vue?macro=true";
import { default as createKo60ts442l2FSzyfcR_45TMtT39_45Xvo3AJ14dPO8_K5w4Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/create.vue?macro=true";
import { default as indexR_45iv2kSWBrmCcCc6O1Ilji0xRSFWplyvVV_451jBLzqQQMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/index.vue?macro=true";
import { default as _91_46_46_46slug_93O5tukpohVEFCB0vIzMuCcOlbC4OnOkYyeGDX8qrywfMMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/[...slug].vue?macro=true";
import { default as createCEZvmPsarauJT68Z8jrnmnDpNq9Fa_NCasrXmmScjhAMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/create.vue?macro=true";
import { default as index8Qv2a3kJc6Fqc4qMIbL9ov1oriwjRDtWzxihMRK_45q_YMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/index.vue?macro=true";
import { default as privacycmJjjWxQx5rlh29mthLH8XbqOvDQ2lPGx5gnQpezJkAMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/privacy.vue?macro=true";
import { default as _91_46_46_46slug_93QuJONlSpoZUsL9ejkPd3Vo4TjFIu2rilswypgMto8KUMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/[...slug].vue?macro=true";
import { default as indexQAj_0G5pdMbb__GHbbuKGCnEa32DBIiVRTAIfZTLZGkMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/index.vue?macro=true";
import { default as rememberAGoo45wK8T0_45ULsfVgeFvJ5V32qt5t3x_3CgPHhDYWAMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/login/pages/remember.vue?macro=true";
import { default as dashboardhIRI9cdMmcjAy1PhUmgO6CBlL6gl_45_kgWCIP7whk9NsMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/savvywords/pages/savvywords/dashboard.vue?macro=true";
import { default as indexktPBdqt6ce7Ho22fwkhBSG7MLFn3hEjqawyhi5ETM40Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/savvywords/pages/savvywords/index.vue?macro=true";
import { default as reportQR8X1ouKAwZiMlLKllian1EQkreNctWJCCvdr4W7QIkMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/stock/pages/stock/report.vue?macro=true";
import { default as termsfZPuXeqmeuofQHYSWnZEORPy4dYNwwIo9GD4JJMTfboMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/terms.vue?macro=true";
export default [
  {
    name: "404___es",
    path: "/404",
    meta: _4049iX_PEWOGuKZGnVL_0MgkWHMhgre4GiAH5qtav5MjZYMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/404.vue")
  },
  {
    name: "404___en",
    path: "/en/404",
    meta: _4049iX_PEWOGuKZGnVL_0MgkWHMhgre4GiAH5qtav5MjZYMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/404.vue")
  },
  {
    name: "adify-budget___es",
    path: "/adify/budget",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/budget.vue")
  },
  {
    name: "adify-budget___en",
    path: "/en/adify/budget",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/budget.vue")
  },
  {
    name: "adify-expired___es",
    path: "/adify/expired",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/expired.vue")
  },
  {
    name: "adify-expired___en",
    path: "/en/adify/expired",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/expired.vue")
  },
  {
    name: "adify-processed___es",
    path: "/adify/processed",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/processed.vue")
  },
  {
    name: "adify-processed___en",
    path: "/en/adify/processed",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/processed.vue")
  },
  {
    name: "analytics___es",
    path: "/analytics",
    meta: index_45D2KBQ4UG9YYORnffUeWBNLiCUcZDyQYUmvVr7qwZhYMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/analytics/pages/analytics/index.vue")
  },
  {
    name: "analytics___en",
    path: "/en/analytics",
    meta: index_45D2KBQ4UG9YYORnffUeWBNLiCUcZDyQYUmvVr7qwZhYMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/analytics/pages/analytics/index.vue")
  },
  {
    name: "briefings-product-briefing-id___es",
    path: "/briefings/:product()/:briefing()/:id()",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/[product]/[briefing]/[id].vue")
  },
  {
    name: "briefings-product-briefing-id___en",
    path: "/en/briefings/:product()/:briefing()/:id()",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/[product]/[briefing]/[id].vue")
  },
  {
    name: "briefings-create___es",
    path: "/briefings/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/create.vue")
  },
  {
    name: "briefings-create___en",
    path: "/en/briefings/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/create.vue")
  },
  {
    name: "briefings___es",
    path: "/briefings",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/index.vue")
  },
  {
    name: "briefings___en",
    path: "/en/briefings",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/index.vue")
  },
  {
    name: "config-companies-slug___es",
    path: "/config/companies/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/[...slug].vue")
  },
  {
    name: "config-companies-slug___en",
    path: "/en/config/companies/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/[...slug].vue")
  },
  {
    name: "config-companies-create___es",
    path: "/config/companies/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/create.vue")
  },
  {
    name: "config-companies-create___en",
    path: "/en/config/companies/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/create.vue")
  },
  {
    name: "config-companies___es",
    path: "/config/companies",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/index.vue")
  },
  {
    name: "config-companies___en",
    path: "/en/config/companies",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/index.vue")
  },
  {
    name: "config-customers-customer-id___es",
    path: "/config/customers/:customer()/:id()",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/[customer]/[id].vue")
  },
  {
    name: "config-customers-customer-id___en",
    path: "/en/config/customers/:customer()/:id()",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/[customer]/[id].vue")
  },
  {
    name: "config-customers-create___es",
    path: "/config/customers/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/create.vue")
  },
  {
    name: "config-customers-create___en",
    path: "/en/config/customers/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/create.vue")
  },
  {
    name: "config-customers___es",
    path: "/config/customers",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/index.vue")
  },
  {
    name: "config-customers___en",
    path: "/en/config/customers",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/index.vue")
  },
  {
    name: "config-documentation-create___es",
    path: "/config/documentation/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/create.vue")
  },
  {
    name: "config-documentation-create___en",
    path: "/en/config/documentation/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/create.vue")
  },
  {
    name: "config-documentation___es",
    path: "/config/documentation",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/index.vue")
  },
  {
    name: "config-documentation___en",
    path: "/en/config/documentation",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/index.vue")
  },
  {
    name: "config-invoices-create___es",
    path: "/config/invoices/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/create.vue")
  },
  {
    name: "config-invoices-create___en",
    path: "/en/config/invoices/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/create.vue")
  },
  {
    name: "config-invoices___es",
    path: "/config/invoices",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/index.vue")
  },
  {
    name: "config-invoices___en",
    path: "/en/config/invoices",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/index.vue")
  },
  {
    name: "config-plans-slug___es",
    path: "/config/plans/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/[...slug].vue")
  },
  {
    name: "config-plans-slug___en",
    path: "/en/config/plans/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/[...slug].vue")
  },
  {
    name: "config-plans-create___es",
    path: "/config/plans/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/create.vue")
  },
  {
    name: "config-plans-create___en",
    path: "/en/config/plans/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/create.vue")
  },
  {
    name: "config-plans___es",
    path: "/config/plans",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/index.vue")
  },
  {
    name: "config-plans___en",
    path: "/en/config/plans",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/index.vue")
  },
  {
    name: "config-products-slug___es",
    path: "/config/products/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/[...slug].vue")
  },
  {
    name: "config-products-slug___en",
    path: "/en/config/products/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/[...slug].vue")
  },
  {
    name: "config-products-create___es",
    path: "/config/products/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/create.vue")
  },
  {
    name: "config-products-create___en",
    path: "/en/config/products/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/create.vue")
  },
  {
    name: "config-products___es",
    path: "/config/products",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/index.vue")
  },
  {
    name: "config-products___en",
    path: "/en/config/products",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/index.vue")
  },
  {
    name: "config-signups-slug___es",
    path: "/config/signups/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/[...slug].vue")
  },
  {
    name: "config-signups-slug___en",
    path: "/en/config/signups/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/[...slug].vue")
  },
  {
    name: "config-signups-create___es",
    path: "/config/signups/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/create.vue")
  },
  {
    name: "config-signups-create___en",
    path: "/en/config/signups/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/create.vue")
  },
  {
    name: "config-signups___es",
    path: "/config/signups",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/index.vue")
  },
  {
    name: "config-signups___en",
    path: "/en/config/signups",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/index.vue")
  },
  {
    name: "config-users-slug___es",
    path: "/config/users/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/[...slug].vue")
  },
  {
    name: "config-users-slug___en",
    path: "/en/config/users/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/[...slug].vue")
  },
  {
    name: "config-users-create___es",
    path: "/config/users/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/create.vue")
  },
  {
    name: "config-users-create___en",
    path: "/en/config/users/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/create.vue")
  },
  {
    name: "config-users___es",
    path: "/config/users",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/index.vue")
  },
  {
    name: "config-users___en",
    path: "/en/config/users",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/index.vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/widgets/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/widgets/pages/index.vue")
  },
  {
    name: "insights-slug___es",
    path: "/insights/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/[...slug].vue")
  },
  {
    name: "insights-slug___en",
    path: "/en/insights/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/[...slug].vue")
  },
  {
    name: "insights-activate___es",
    path: "/insights/activate",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/activate/index.vue")
  },
  {
    name: "insights-activate___en",
    path: "/en/insights/activate",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/activate/index.vue")
  },
  {
    name: "insights-create___es",
    path: "/insights/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/create.vue")
  },
  {
    name: "insights-create___en",
    path: "/en/insights/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/create.vue")
  },
  {
    name: "insights___es",
    path: "/insights",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/index.vue")
  },
  {
    name: "insights___en",
    path: "/en/insights",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/index.vue")
  },
  {
    name: "login___es",
    path: "/login",
    meta: loginJnXkW7XIZYBTy7N_BRVCuULOU3jGKBpK_45bv7UhPj9NsMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/login/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginJnXkW7XIZYBTy7N_BRVCuULOU3jGKBpK_45bv7UhPj9NsMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/login/pages/login.vue")
  },
  {
    name: "parameters-slug___es",
    path: "/parameters/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/[...slug].vue")
  },
  {
    name: "parameters-slug___en",
    path: "/en/parameters/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/[...slug].vue")
  },
  {
    name: "parameters-create___es",
    path: "/parameters/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/create.vue")
  },
  {
    name: "parameters-create___en",
    path: "/en/parameters/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/create.vue")
  },
  {
    name: "parameters___es",
    path: "/parameters",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/index.vue")
  },
  {
    name: "parameters___en",
    path: "/en/parameters",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/index.vue")
  },
  {
    name: "plannings-slug___es",
    path: "/plannings/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/[...slug].vue")
  },
  {
    name: "plannings-slug___en",
    path: "/en/plannings/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/[...slug].vue")
  },
  {
    name: "plannings-create___es",
    path: "/plannings/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/create.vue")
  },
  {
    name: "plannings-create___en",
    path: "/en/plannings/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/create.vue")
  },
  {
    name: "plannings___es",
    path: "/plannings",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/index.vue")
  },
  {
    name: "plannings___en",
    path: "/en/plannings",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/index.vue")
  },
  {
    name: "privacy___es",
    path: "/privacy",
    meta: privacycmJjjWxQx5rlh29mthLH8XbqOvDQ2lPGx5gnQpezJkAMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    meta: privacycmJjjWxQx5rlh29mthLH8XbqOvDQ2lPGx5gnQpezJkAMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/privacy.vue")
  },
  {
    name: "Visualización___es",
    path: "/products/:slug(.*)*",
    meta: _91_46_46_46slug_93QuJONlSpoZUsL9ejkPd3Vo4TjFIu2rilswypgMto8KUMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/[...slug].vue")
  },
  {
    name: "Visualización___en",
    path: "/en/products/:slug(.*)*",
    meta: _91_46_46_46slug_93QuJONlSpoZUsL9ejkPd3Vo4TjFIu2rilswypgMto8KUMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/[...slug].vue")
  },
  {
    name: "Productos___es",
    path: "/products",
    meta: indexQAj_0G5pdMbb__GHbbuKGCnEa32DBIiVRTAIfZTLZGkMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/index.vue")
  },
  {
    name: "Productos___en",
    path: "/en/products",
    meta: indexQAj_0G5pdMbb__GHbbuKGCnEa32DBIiVRTAIfZTLZGkMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/index.vue")
  },
  {
    name: "remember___es",
    path: "/remember",
    meta: rememberAGoo45wK8T0_45ULsfVgeFvJ5V32qt5t3x_3CgPHhDYWAMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/login/pages/remember.vue")
  },
  {
    name: "remember___en",
    path: "/en/remember",
    meta: rememberAGoo45wK8T0_45ULsfVgeFvJ5V32qt5t3x_3CgPHhDYWAMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/login/pages/remember.vue")
  },
  {
    name: "savvywords-dashboard___es",
    path: "/savvywords/dashboard",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/savvywords/pages/savvywords/dashboard.vue")
  },
  {
    name: "savvywords-dashboard___en",
    path: "/en/savvywords/dashboard",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/savvywords/pages/savvywords/dashboard.vue")
  },
  {
    name: "savvywords___es",
    path: "/savvywords",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/savvywords/pages/savvywords/index.vue")
  },
  {
    name: "savvywords___en",
    path: "/en/savvywords",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/savvywords/pages/savvywords/index.vue")
  },
  {
    name: "stock-report___es",
    path: "/stock/report",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/stock/pages/stock/report.vue")
  },
  {
    name: "stock-report___en",
    path: "/en/stock/report",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/stock/pages/stock/report.vue")
  },
  {
    name: "terms___es",
    path: "/terms",
    meta: termsfZPuXeqmeuofQHYSWnZEORPy4dYNwwIo9GD4JJMTfboMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/terms.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms",
    meta: termsfZPuXeqmeuofQHYSWnZEORPy4dYNwwIo9GD4JJMTfboMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/terms.vue")
  }
]