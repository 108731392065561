import { updateAppConfig } from '#app'

export default defineNuxtPlugin({
  name: 'load-client-config',
  enforce: 'pre',

  async setup (nuxtApp) {
    const clientAlias = useRuntimeConfig().public.project as string || null

    if (!clientAlias || clientAlias === 'default') {
      console.warn('ℹ️ No client alias detected, using default app config.')
      return
    }

    try {
      const clientConfigModule = await import(`../../config/clients/${clientAlias}.ts`)
      const clientConfigOverrides = clientConfigModule.default

      if (clientConfigOverrides && typeof clientConfigOverrides === 'object') {
        console.log(`🚀 Aplicando appConfig overrides para: ${clientAlias}`)
        updateAppConfig(clientConfigOverrides)
      } else {
        console.warn(`⚠️ No hay appConfig overrides válidos para: ${clientAlias}`)
      }

    } catch (e) {
      console.error(`❌ No hay appConfig overrides para ${clientAlias}. Usando appConfig por defecto..`, e)
    }
  }
})